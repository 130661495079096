import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Img from "gatsby-image"
import {Link} from "gatsby";
import { Date } from "prismic-reactjs"
import linkResolver from "../../utils/linkResolver";

const Article = styled.div`
     max-width: ${props => props.news ? "360px" : "347px"};
     margin: auto;
     @media(min-width: ${breakpoints.sm}){
         max-width: 100%;
         height: auto;     
     }
     @media(min-width: ${breakpoints.md}){
         max-width: ${props => props.news ? "360px" : "600px"};
         height: auto;     
     }
     @media(min-width: ${breakpoints.xl}){
         max-width: ${props => props.news ? "360px" : "274px"};
    }
`

const ArticleDate = styled.div`
    font-size: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    ${fonts.dmSansBold};
    color: ${colors.grey};
    padding-top: 25px;
    padding-bottom: 11px;
    width: 100%;
`

const ArticleImage = styled(Img)`
    width: 100%;
    height: ${props => props.news ? "231px" : "232px"};
    margin: auto;
    @media(min-width: ${breakpoints.sm}){
        height: ${props => props.news ? "240px" : "400px"};
    }
    @media(min-width: ${breakpoints.xl}){
        height: ${props => props.news ? "240px" : "183px"};
    }
`

const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${colors.darkBlue};
  ${fonts.jaggerRegular};
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 26px;
  margin-bottom: ${props => props.news ? "20px" : "57px"};
  display: block;
  @media(min-width: ${breakpoints.md}){
      margin-left: auto;
      margin-right: auto;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const IntroText = styled.div`
    margin-bottom: ${props => props.news ? "60px" : "0"};
    @media(min-width: ${breakpoints.lg}){
        margin-bottom: ${props => props.news ? "80px" : "0"};
    }
`

const ArticleCard = ({article, news}) => {
  const articleLink = linkResolver(article._meta);

  const date = Date(article.published_at)
  const publishedAt = Intl.DateTimeFormat(article._meta.lang, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date)

  return (
    <Article news={news}>
      <Link to={articleLink}>
        <ArticleImage fluid={article.thumbnail_imageSharp.childImageSharp.fluid} alt={article.title} news={news}/>
      </Link>
      <ArticleDate>{publishedAt}</ArticleDate>
      <TitleLink to={articleLink} news={news}>
        {article.title}
      </TitleLink>
      {/*<IntroText news={news}>{intro && intro}</IntroText>*/}
    </Article>
  )
}


export default ArticleCard

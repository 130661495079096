import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Date } from "prismic-reactjs"
import linkResolver from "../../utils/linkResolver";

const ArticleImage = styled(Img)`
    margin: auto;
    height: ${props => props.carousel ? "363px" : "232px"};
    max-width: ${props => props.carousel ? "312px" : "347px"};
    @media(min-width: ${breakpoints.sm}){
        height: ${props => props.carousel ? "363px" : "316px"};
        max-width: ${props => props.carousel ? "312px" : "600px"};
     }
     @media(min-width: ${breakpoints.md}){
        height: ${props => props.carousel ? "363px" : "400px"};
        max-width: ${props => props.carousel ? "312px" : "600px"};
     }
    @media(min-width: ${breakpoints.xl}){
        height: ${props => props.carousel ? "363px" : "669px"};
        max-width: ${props => props.carousel ? "312px" : "576px"};
    }
`

const ArticleCard = styled.div`
     max-width: ${props => props.carousel ? "312px" : "347px"};
     position: relative;
     margin: auto;
     @media(min-width: ${breakpoints.sm}){
         max-width: ${props => props.carousel ? "auto" : "100%"};
         height: auto;     
     }
     @media(min-width: ${breakpoints.md}){
         width: ${props => props.carousel ? "312px" : "600px"}; 
         height: ${props => props.carousel ? "363px" : "auto"}; 
     }
     @media(min-width: ${breakpoints.xl}){
         max-width: ${props => props.carousel ? "312px" : "576px"};
         height: ${props => props.carousel ? "363px" : "669px"}; 
         margin: auto;  
     }
`

const ArticleDetails = styled.div`
    position: ${props => props.carousel ? "absolute" : "relative"};
    margin-left: ${props => props.carousel ? "28px" : "0"};
    bottom: ${props => props.carousel ? "33px" : "0"};
    @media(min-width: ${breakpoints.xl}){
        position: absolute;
        margin-left: ${props => props.carousel ? "28px" : "52px"};
        max-width: 432px;
        bottom: 33px;
        padding-bottom: ${props => props.carousel ? "0" : "64px"};
    }
`

const ArticleDate = styled.div`
    font-size: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    ${fonts.dmSansBold};
    color: ${props => props.carousel ? colors.white : colors.grey};
    padding-top: 25px;
    padding-bottom: 11px;
    @media(min-width: ${breakpoints.xl}){
        color: ${colors.white};
        letter-spacing: 1.5px;
        padding-bottom: 16px;
    }
`

const ArticleTitle = styled.div`
    color: ${props => props.carousel ? colors.white : colors.darkBlue};
    ${fonts.jaggerRegular};
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 26px;
    margin-bottom: ${props => props.carousel ? "0" : "57px"};
    max-width: 250px;
    @media(min-width: ${breakpoints.xl}){
        color: ${colors.white};
        font-size: 28px;
        line-height: 38px;
        letter-spacing: -0.8px;
        margin-bottom: 0;
    }
`

const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  &:hover {
    text-decoration: underline;
  }
`

const Overlay = styled.div`
    display: block;
    position: absolute;
    bottom: 0;
    width: ${props => props.carousel ? "312px" : "576px"};
    height: 50%;
    background: linear-gradient(180deg, rgba(241,241,241,0.0) 0%, rgba(7,13,73,1) 90%);
    @media(max-width: ${breakpoints.xl}){
        display: ${props => props.carousel ? "block" : "none"};
    }
`

const StyledLink = styled(Link)`
  display: block;
`;

const ArticleCardLarge = ({ article, carousel }) => {
  const date = Date(article.published_at)
  const publishedAt = Intl.DateTimeFormat(article._meta.lang, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date)

  const articleLink = linkResolver(article._meta)

  return (
    <ArticleCard carousel={carousel}>
      <StyledLink to={articleLink}>
        <ArticleImage fluid={article.thumbnail_imageSharp.childImageSharp.fluid} alt={article.title}
                      carousel={carousel}/>
        <Overlay carousel={carousel}/>
      </StyledLink>
      <ArticleDetails carousel={carousel}>
        <ArticleDate carousel={carousel}>{publishedAt}</ArticleDate>
        <TitleLink to={articleLink} carousel={carousel}>
          <ArticleTitle carousel={carousel}>{article.title}</ArticleTitle>
        </TitleLink>
      </ArticleDetails>
    </ArticleCard>
  )
}

export default ArticleCardLarge

import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import get from "lodash/get"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import ArticleCardLarge from "../components/core/ArticleCardLarge"
import ArticleCard from "../components/core/ArticleCard"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import { Row, Col, Container } from "styled-bootstrap-grid"
import BlueLineImg from "../resources/img/icons/blue-line.svg"
import Slider from "react-slick"
import LeftArrow from "../resources/img/home/left-arrow.svg"
import RightArrow from "../resources/img/home/right-arrow.svg"
import {graphql} from "gatsby";

const StyledRow = styled(Row)`
    margin: 0 33px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
    @media(min-width: ${breakpoints.xl}){
        margin: 0 33px;
    }
    @media(min-width: ${breakpoints.xxl}){
        margin: 0;
    }
`

const HeroNews = styled.div`
    background: ${colors.lightBlue};
    padding-top: 178px;
    @media(min-width: ${breakpoints.lg}){
        padding-top: 213px;
    }
`

const HeroTitle = styled.h1`
    font-size: 42px;
    color: ${colors.darkBlue};
    letter-spacing: -1.35px;
    line-height: 52px;
    border-left: 2px solid ${colors.blue};
    display: block;
    padding-left: 14px;
    ${fonts.jaggerRegular};
    @media(min-width: ${breakpoints.lg}){
        font-size: 62px;
        letter-spacing: -2px;
        line-height: 72px;
        padding-left: 18px;
        max-width: 403px;
    }
`

const HeroSubtitle = styled.h1`
    display: block;
    padding-top: 60px;
    padding-bottom: 54px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.5px;
    ${fonts.dmSansBold};
    &:before {
        content: url(${BlueLineImg});
        padding-right: 19px;
    }
    @media(min-width: ${breakpoints.lg}){
        padding-top: 90px;
        padding-bottom: 44px;
    }
`

const Slide = styled.div`
    outline: none;
    border: 0;
    padding-bottom: 80px;
    @media(min-width: ${breakpoints.lg}){
        padding-bottom: 160px;
    }
`

const ControllerContainer = styled.div`
    position: absolute;
    right: 0;
    margin-top: -63px;
    display: none;
    @media(min-width: ${breakpoints.lg}){
        display: block;
    }
`

const CarouselController = styled.img`
    cursor: pointer;
    border: none;
    width: 16px;
    height: 16px;
    padding: 24px;
`

const Previous = styled.div`
    cursor: pointer;
    background-color: ${colors.white};
    height: 63px;
    width: 63px;
    display: inline-block;
    
    @media(min-width: 1300px){
      display: none;
    }
`

const Next = styled.div`
    cursor: pointer;
    background-color: ${colors.white};
    height: 63px;
    width: 63px;
    display: inline-block;
    margin-left: 3px;
    
    @media(min-width: 1300px){
      display: none;
    }
`

const NewsContainer = styled(Container)`
    padding-top: 90px;
`

class NewsPage extends React.Component {
  state = {
    slideIndex: 0,
  }

  changeSlide(diff) {
    const slideIndex = this.state.slideIndex + diff

    this.slider.slickGoTo(slideIndex)
  }

  render() {
    const path = this.props.location.pathname
    const { t, i18n } = this.props
    var settings = {
      dots: false,
      arrows: false,
      infinite: false,
      fade: false,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      initialSlide: 0,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    let articles
    if (i18n.language === "fr") {
      articles = get(this, "props.data.prismic.articlesFr.edges")
    } else {
      articles = get(this, "props.data.prismic.articles.edges")
    }

    let mustReadArticles = []
    let articlesData = []

    articles.map((articleData) => {
      const node = articleData.node

      if (!node.thumbnail_imageSharp) {
        return
      }

      if (mustReadArticles.length <= 4
        && node._meta.tags.includes("Must Read Article")) {
        mustReadArticles.push(node)
      } else {
        articlesData.push(node)
      }
    })


    return (
      <Layout meta={{
        uid: 'news',
        lang: i18n.language === 'en' ? 'en-us' : 'fr-fr',
        alternateLanguages:[{ uid: 'news', lang: i18n.language === 'en' ? 'fr-fr': 'en-us' }],
      }}>
        <SEO title={t("news.title")}
             description={t("news.description")}
             fullTitle={true}
             path={path}/>
        <HeroNews>
          <Container>
            <StyledRow>
              <Col sm={12}>
                <HeroTitle>{t("news.description")}</HeroTitle>
                <HeroSubtitle>{t("news.mustReadArticles")}</HeroSubtitle>
              </Col>
            </StyledRow>
            <Slider ref={slider => (this.slider = slider)} {...settings}>
              {mustReadArticles.map((article, index) => {
                return (
                  <Slide key={index}>
                    <ArticleCardLarge article={article} carousel={1}/>
                  </Slide>
                )
              })}
            </Slider>
            <ControllerContainer>
              <Previous onClick={() => this.changeSlide(-1)}><CarouselController src={LeftArrow}/></Previous>
              <Next onClick={() => this.changeSlide(1)}><CarouselController src={RightArrow}/></Next>
            </ControllerContainer>
          </Container>
        </HeroNews>
        <NewsContainer>
          <StyledRow>
            {articlesData.map((article, index) => {
              return (
                <Col xl={4} lg={6} key={index}>
                  <ArticleCard article={article} news={1}/>
                </Col>
              )
            })}
          </StyledRow>
        </NewsContainer>
      </Layout>
    )
  }
}

export default withTranslation()(NewsPage)

export const pageQuery = graphql`
    query newsPageQuery {
        prismic {
            articles: allArticles(lang: "en-gb", sortBy: published_at_DESC) {
                edges {
                    node {
                        _meta {
                          uid
                          tags
                          lang
                          type
                        }
                        published_at
                        title
                        intro
                        thumbnail_image
                        thumbnail_imageSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 360) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
            }
            articlesFr: allArticles(lang: "fr-fr", sortBy: published_at_DESC) {
                edges {
                    node {
                        _meta {
                          uid
                          tags
                          lang
                          type
                        }
                        published_at
                        title
                        intro
                        thumbnail_image
                        thumbnail_imageSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 360) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

